<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <ChLoading size="sm" v-if="isSecLoading" class="spinner mx-auto" />
    <h1 v-else class="title">{{ sections.name }}</h1>

    <home-section-infinite-characters
      class="my-2"
      :characters="characters"
      :paging="paging"
      :is-loading="!isSecLoading && areCharsLoading"
      @loadMore="requestLoadMore"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HomeSectionInfiniteCharacters from './components/HomeSectionInfiniteCharacters.vue';
import { getSectionCharacters, getHomeSectionDetails } from '@/shared/actions/characters';
import { Paging, Section } from '@/shared/types/static-types';

import { getNextPage } from '@/shared/helpers/pagination';

@Options({
  name: 'HomeSectionPage',
  components: {
    HomeSectionInfiniteCharacters,
  },
})
export default class HomeSectionPage extends Vue {
  public sections: Section = {};
  public characters = [];
  public isSecLoading = true;
  public areCharsLoading = true;
  public paging: Paging | null = null;

  public async created() {
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.fetchHomeSectionDetails();
  }

  public async requestLoadMore() {
    this.areCharsLoading = true;
    try {
      const { results, ...paging } = await getNextPage(this.paging!);
      this.characters = this.characters.concat(results);
      this.paging = paging;
    } catch (e) {}

    this.areCharsLoading = false;
  }

  public async fetchHomeSectionDetails() {
    const router = useRouter();
    this.isSecLoading = true;
    try {
      const res = await getHomeSectionDetails(router.currentRoute.value.params.slug);
      this.sections = res;
      this.fetchHomeSectionCharacters();
    } catch (_err) {
      router.push({ name: 'home' });
    }
    this.isSecLoading = false;
  }

  public async fetchHomeSectionCharacters() {
    const router = useRouter();
    this.areCharsLoading = true;

    await new Promise((r) => setTimeout(r, 2000));

    try {
      const { results, ...paging } = await getSectionCharacters(this.sections.id, 1);

      this.characters = results;
      this.paging = paging;
    } catch (_err) {
      router.push({ name: 'home' });
    }
    this.areCharsLoading = false;
  }
}
</script>
<style lang="sass" scoped>
.title
  font-weight: bold
</style>
