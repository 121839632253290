<template>
  <div>
    <div v-if="characters && characters.length">
      <character-card-small
        v-for="(character, index) of characters"
        :key="character.id"
        :character="character"
        :comments-count="get(charCommentsCounts, `${character.id}.comments_count`) || 0"
        :show-comment-counts="true"
        class="my-3"
        @afterReaction="(reactionResp) => onReaction(index, reactionResp)"
      />
      <p v-if="nextPageExists && !isLoading" class="clickable" @click="requestLoadMore">Load More</p>
      <p v-else-if="!isLoading">End of list</p>
    </div>
    <div v-else-if="!isLoading" class="no-data">No characters yet</div>

    <div v-if="isLoading" class="d-flex justify-content-center mt-3">
      <ChLoading size="sm" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import CharacterCardSmall from '@/shared/components/CharacterCardSmall.vue';
import { Character } from '@/shared/types/static-types';

import { getCommentsCount } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';

@Options({
  name: 'HomeSectionInfiniteCharacters',
  components: {
    CharacterCardSmall,
  },
})
export default class HomeSectionInfiniteCharacters extends Vue {
  @Prop() public characters!: Character[];
  @Prop() public paging!: any;
  @Prop() public isLoading!: boolean;

  public charCommentsCounts = {};
  public areCommentCountsLoading = false;

  public requestLoadMore() {
    this.$emit('loadMore');
  }

  get = get;

  @Watch('characters')
  charactersChanged() {
    if (get(this.characters, 'length')) {
      this.fetchCharsCommentCounts();
    }
  }

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public async fetchCharsCommentCounts() {
    this.areCommentCountsLoading = true;
    this.charCommentsCounts = {};
    const resp = await getCommentsCount(map(this.characters, 'id'), constants.commentedAsTypes.CHARACTER);
    this.charCommentsCounts = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
    this.areCommentCountsLoading = false;
  }

  public onReaction(characterIndex: number, reaction: any) {
    this.characters[characterIndex] = { ...this.characters[characterIndex], ...reaction.updatedReactionsData };
  }
}
</script>

<style scoped lang="sass"></style>
